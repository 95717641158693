<template>
  <v-layout column wrap>
    <v-card>
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">LIST OF RAW LOTS PAYMENT</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-data-table
        :height="height"
        :headers="headers"
        :items="list_of_share_capital"
        :search="search"
        :loading="data_table_loading"
        loading-text="Loading... Please wait"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-select
              v-model="month_of"
              class="mx-3 pt-5"
              :items="month_of_items"
              item-value="id"
              item-text="month_of"
              label="Month Of"
              required
              @change="get_list_of_share_capital_items"
              :rules="rules.combobox_rule"
            ></v-select>
            <v-flex xs12 md4>
                        <span class="headline" v-if="can_print">
                                                Print
                                                <v-icon
                                                  class="mr-2"
                                                  color="warning"
                                                  @click="print_data()"
                                                >
                                                {{icons.mdiPrinter}}
                                                </v-icon>
                                            </span>
            </v-flex>
            <v-spacer></v-spacer>

            <v-text-field
              class="mx-2"
              :append-icon="icons.mdiAccountSearchOutline"
              label="Search"
              single-line
              hide-details
              v-model="search"
            ></v-text-field>
          </v-toolbar>
        </template>
      </v-data-table>
    </v-card>
  </v-layout>
</template>

<script>
  import {mapActions, mapGetters} from 'vuex'
  import moment from "moment";
  import {mdiAccountSearchOutline, mdiPrinter} from '@mdi/js'

  export default {
    setup() {
      return {
        icons: {
          mdiAccountSearchOutline,
          mdiPrinter,
        },
      }
    },
    data() {
      return {
        total: 0,
        commission: 0,
        net: 0,
        can_print: false,
        month_of: '',
        list_of_share_capital: [],
        month_of_items: [],
        //table props
        search: '',
        data_table_loading: false,
        headers: [
          {text: 'ID', value: 'id'},
          {text: 'CMF #', value: 'cmf_no'},
          {text: 'Name', value: 'name'},
          {text: 'Amount', value: 'amount'},
          {text: 'Commission', value: 'commission'},
          {text: 'Net', value: 'net'},
        ],
        height: 430
      }
    },
    mounted() {
      const data = new FormData()
      data.append('branch_id', this.branch_id);
      this.retrieve_transaction_month(data)
        .then(response => {
          this.month_of_items = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('system_data', ['company_logo']),
      ...mapGetters('authentication', ['branch_id','branch', 'branch_address', 'branch_contact_no']),
    },
    watch: {
      branch_id: function () {
        this.can_print = false
        this.month_of = ''
        this.list_of_share_capital = []
        this.total = '0'
        this.commission = '0'
        this.net = '0'
      }
    },
    methods: {
      ...mapActions('transaction_months', ['retrieve_transaction_month']),
      ...mapActions('raw_lots_payment', ['get_list_of_raw_lots_payment']),
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      get_list_of_share_capital_items() {
        if (this.month_of_items.length > 0) {
          var index = this.month_of_items.map(function (x) {
            return x.id
          }).indexOf(this.month_of)
          if (index != -1) {
            this.data_table_loading = true
            const data = new FormData()
            data.append('month_of', this.month_of_items[index].month_of);
            data.append('branch_id', this.branch_id);
            this.get_list_of_raw_lots_payment(data)
              .then((response) => {
                this.can_print = true
                this.list_of_share_capital = response.data[0].datas
                this.total = response.data[0].total
                this.commission = response.data[0].total_commission
                this.net = response.data[0].total_net
                this.data_table_loading = false
              })
              .catch(error => {
                console.log(error)
              })
          }
        }
      },
      print_data() {
        var imgData = this.company_logo
        var array = []
        var indexMonth = this.month_of_items.map(function (x) {
          return x.id
        }).indexOf(this.month_of)

        var month = this.month_of_items[indexMonth].month_of
        array.push(
          [
            {text: 'ID', alignment: 'center', style: 'label'},
            {text: 'Control No', alignment: 'center', style: 'label'},
            {text: 'Name', alignment: 'center', style: 'label'},
            {text: 'Amount', alignment: 'center', style: 'label'},
            {text: 'Commission', alignment: 'center', style: 'label'},
            {text: 'Net', alignment: 'center', style: 'label'},
          ]
        )
        if (this.list_of_share_capital.length > 0) {
          var total_amount = this.total
          var total_commission = this.commission
          var total_net = this.net
          this.list_of_share_capital.forEach(function (item, index, payment) {
            array.push(
              [
                {text: item.id, alignment: 'center'},
                {text: item.cmf_no, alignment: 'left'},
                {text: item.name, alignment: 'left'},
                {text: item.amount, alignment: 'center'},
                {text: item.commission, alignment: 'center'},
                {text: item.net, alignment: 'center'},
              ]
            )
            if (Object.is(payment.length - 1, index)) {
              // execute last item logic
              array.push(
                [
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {text: '', alignment: 'left', border: [false, false, false, false]},
                  {
                    color: 'red',
                    text: 'TOTAL: ',
                    alignment: 'right',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_amount,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_commission,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                  {
                    color: 'red',
                    text: total_net,
                    alignment: 'left',
                    border: [false, false, false, false]
                  },
                ]
              )
            }
          })
        } else {
          array.push(
            [
              '----',
              '----',
              '----',
              '----',
            ]
          )
        }
        //call pdfmake
        var pdfMake = require('pdfmake/build/pdfmake.js')
        if (pdfMake.vfs == undefined) {
          var pdfFonts = require('pdfmake/build/vfs_fonts.js')
          pdfMake.vfs = pdfFonts.pdfMake.vfs;
        }
        var docDefinition = {
          pageSize: 'LETTER',
          pageOrientation: 'portrait',
          content: [
            {
              columns: [
                {image: imgData, width: 54, height: 54, style: 'logo'},
                {
                  stack: [
                    'GOODLIFE SAVINGS & CREDIT COOPERATIVE',
                    {text: this.branch_address, style: 'subheader'},
                    {
                      text: 'C.D.A. REG. No. 9520-1011000000048747 CONTACT NO.: ' + this.branch_contact_no,
                      style: 'subheader'
                    },
                  ],
                  style: 'header'
                },
              ]
            },
            {text: 'LIST OF RAW LOTS PAYMENTS', style: 'title'},
            '================================================================================',
            {text: 'Printed as of: ' + moment().format('MMMM D, YYYY'), style: 'printed_label'},
            {text: 'Branch: ' + this.branch, style: 'main_info'},
            {text: 'Month of: ' + month, style: 'main_info'},
            ' ',
            {
              style: 'tableExample',
              color: '#444',
              table: {
                widths: [30, 50, 100, 50, 50, 50],
                body: array,
              },
              layout: {
                fillColor: function (rowIndex) {
                  return (rowIndex === 0) ? '#CCCCCC' : null;
                },
              },
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Prepared By: ', style: 'aa_top_margin'},
                    {text: 'Received By:', style: 'cashier_top_margin'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'aa_for'},
                    {text: '', style: 'cashier_for'},
                    {text: ''},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'aa_line'},
                    {text: '_________________________________', style: 'cashier_line'},
                    {text: ''},
                  ]
                },
              ]
            },
            {
              stack: [
                {
                  columns: [
                    {text: 'Verified By: ', style: 'bm_top_margin'},
                    {text: 'Audited By: ', style: 'au_top_margin'},
                  ]
                },
                {
                  columns: [
                    {text: '', style: 'bm_for'},
                    {text: '', style: 'au_for'},
                  ]
                },
                {
                  columns: [
                    {text: '_________________________________', style: 'bm_line'},
                    {text: '_________________________________', style: 'au_line'},
                  ]
                },
                {
                  columns: [
                    {text: 'Branch Manager', style: 'bm_placeholder'},
                    {text: 'Asst. Auditor', style: 'au_placeholder'},
                  ]
                },
              ]
            },
          ],
          footer: {
            columns: [
              {
                text: 'Generated with Goodlife Savings & Credit Cooperative System',
                alignment: 'center',
                style: 'tableExample'
              }
            ]
          },
          styles: {
            header: {
              fontSize: 21,
              bold: true,
              alignment: 'left',
              margin: [6, 6, 0, 20]//[left, top, right, bottom]
            },
            subheader: {
              fontSize: 12
            },
            title: {
              fontSize: 15,
              alignment: 'center',
            },
            logo: {
              alignment: 'center',
              margin: [0, 0, 0, 0]//[left, top, right, bottom]
            },
            printed_label: {
              alignment: 'right',
              fontSize: 9,
              margin: [0, 4, 0, 0]//[left, top, right, bottom]
            },
            main_info: {
              margin: [0, 2, 0, 2],
              fontSize: 10,
            },
            sub_info: {
              margin: [0, 2, 0, 2],
              fontSize: 8,
            },
            tableExample: {
              fontSize: 7,
            },

            aa_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            bm_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            cs_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            aa_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_placeholder: {
              margin: [15, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            aa_for: {
              margin: [0, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_for: {
              margin: [3, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cashier_placeholder: {
              margin: [26, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            cs_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            bm_placeholder: {
              margin: [5, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_top_margin: {
              margin: [0, 10, 0, 0],
              alignment: 'left',
              fontSize: 7,
            },
            au_placeholder: {
              margin: [2, 2, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_for: {
              margin: [2, 15, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
            au_line: {
              margin: [0, 0, 0, 2],
              alignment: 'left',
              fontSize: 7,
            },
          },
        }
        pdfMake.createPdf(docDefinition).open();
      },
    }
  }
</script>
